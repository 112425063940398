import React from "react";
import PropTypes from "prop-types";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useNavigate, useLocation } from "react-router-dom";
import { testAttr } from "./Constants";
import parse from 'html-react-parser';
import "./NewsList.scss";

const NewsList = ({ list, onItemClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNewsClick = (newsItem) => {
    const isOnNewsPage = location.pathname.startsWith('/news');

    if (isOnNewsPage) {
      onItemClick(newsItem); 
    } else {
      navigate(`/news`, { state: { dataSelected: newsItem } });
    }
  };

  return (
    <div data-testid={testAttr.LIST_CONTAINER} className="newsListContainer">
      <Stack direction="column" spacing={2}>
        {list?.map((newsItem) => (
          <div 
            key={newsItem.id} 
            className="newsItem" 
            onClick={() => handleNewsClick(newsItem)}
          >
            <div className="newsListHeader">
              <Avatar
                alt={newsItem.title}
                src={`/${newsItem.image}`}
                sx={{ width: 56, height: 56 }}
              />
              <h3 className="newsTitle">{newsItem.title}</h3>
            </div>
            <i className="newsDateList">{newsItem.date}</i>
            <p className="newsDescription">{parse(newsItem.description)}</p>
          </div>
        ))}
      </Stack>
    </div>
  );
};

NewsList.propTypes = {
  list: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default NewsList;