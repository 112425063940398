import React from "react";
import PropTypes from "prop-types";
import CardContainer from "../../Atoms/Card/Card";
import sortFestList from "../../../utils/sortFestList/sortFestList";
import { testAttr, imagesPath } from "./Constants";
import { useTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../i18n";
import { useNavigate } from "react-router-dom"; 
import "./Home.scss";

const Home = ({ homeFestList, onClickFest }) => {
  const festListSorted = sortFestList(homeFestList);
  let festList = [...festListSorted];

  if (festListSorted.length < 4) {
    for (let i = 0; festList.length < 4 && i < homeFestList.length; i++) {
      if (!festList.some(fest => fest === homeFestList[i])) {
        festList.push(homeFestList[i]);
      }
    }
  }

  const { t } = useTranslation();
  const navigate = useNavigate();

  // Handle card click to navigate to the specific fest page
  const handleCardClick = (festId, festName) => {
    const customUrl = festName.toLowerCase().replace(/ /g, "-");
    navigate(`/fest/${customUrl}`);
    onClickFest(festId);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <div className="customPageTitles">
        <h2>{t("upcomingFestivals")}</h2>
      </div>
      <div data-testid={testAttr.HOME_CONTAINER} className="container">
        {festList?.map((festElement) => (
          festElement.isActive && <CardContainer
            key={festElement.id}
            title={festElement.name}
            festDescription={festElement.festDescription}
            path={`${imagesPath}${festElement.imgPaths}`}
            onClick={() => handleCardClick(festElement.id, festElement.name)}
          />
        ))}
      </div>
    </I18nextProvider>
  );
};

Home.propTypes = {
  homeFestList: PropTypes.array.isRequired,
  onClickFest: PropTypes.func,
};

export default Home;
