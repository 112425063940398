const newsInEnglish = [
  {
    id: 'news-3-fest-2025',
    title: '2025 Un Año de Celebración',
    image: 'assets/images/news/2025-colombia-celebrate.jpg',
    date: '15/11/2024',
    description: `<p>Colombia se prepara para un emocionante año de festivales en 2025, con eventos destacados en varios géneros musicales y culturales. El Festival Estéreo Picnic, uno de los más esperados, se llevará a cabo del 27 al 30 de marzo en Bogotá, y contará con una alineación de estrellas internacionales y locales. Artistas como Olivia Rodrigo y el dúo francés Justice se encuentran entre los confirmados, atrayendo tanto a fanáticos de la música pop como a seguidores de la escena electrónica.</p>
      <p>Por otro lado, el Freedom Festival de Medellín, dedicado a la música techno y house, celebrará su 17ª edición del 21 al 23 de febrero. Este evento es conocido por su enfoque en el talento emergente y el groove, con actividades como talleres, paneles académicos y presentaciones en vivo para complementar su intensa propuesta de música electrónica.</p>
      <p>Finalmente, el Hay Festival, que celebra sus 20 años en Cartagena, traerá escritores, pensadores y artistas de todo el mundo del 30 de enero al 2 de febrero. Este año, el evento se centrará en 20 preguntas clave sobre temas actuales, ofreciendo a los asistentes una experiencia de diálogo y reflexión</p>`,
  },
  {
    id: 'news-2',
    title: 'Shakira World Tour',
    image: 'assets/images/news/shakira-world-tour.jpg',
    date: '09/10/2024',
    description: `<p>La superestrella global Shakira está lista para electrificar Sudamérica en 2025 con su tan esperada gira de conciertos. Conocida por sus dinámicas actuaciones, su mezcla de pop latino, rock y reguetón, y su cautivadora presencia en el escenario, la gira de Shakira es uno de los eventos más esperados del año.</p>

      <p>Los fanáticos pueden esperar que interprete una mezcla de éxitos clásicos como "Hips Don’t Lie", "Whenever, Wherever" y nuevas canciones de sus últimos álbumes. Es probable que la etapa sudamericana de la gira cubra las principales ciudades de Colombia, Argentina, Brasil y Chile, brindando a millones de seguidores de la región la oportunidad de vivir su magia en vivo.</p>

      <p>Con la reputación de Shakira por ofrecer shows llenos de energía y espectaculares efectos visuales, la gira de 2025 promete ser una experiencia inolvidable, marcando su gran regreso al continente después de años de giras mundiales. El anuncio ya ha generado gran emoción, y se espera que las entradas se agoten rápidamente.</p>

      <p>¡Estén atentos para conocer las fechas, los lugares y más detalles oficiales mientras Shakira se prepara para conquistar nuevamente los escenarios de Sudamérica!</p>
    `,
  },
  {
    id: 'news-1',
    title: 'Enrique Bunbury regresará a Colombia',
    image: 'assets/images/news/bunbury-colombia-2025.jpeg',
    date: '07/10/2024',
    description: `<p>El legendario músico español Enrique Bunbury regresará a Colombia en 2025 para ofrecer un concierto inolvidable. Conocido por su distintiva voz y estilo que fusiona rock, bolero y sonidos alternativos, Bunbury ha conquistado una sólida base de fanáticos en América Latina.</p>

      <p>El exvocalista de Héroes del Silencio interpretará tanto éxitos de su extensa carrera en solitario como clásicos que marcaron su trayectoria, como "Entre dos tierras" y "El extranjero". Su show promete ser una experiencia única, cargada de emociones y la energía característica de sus presentaciones en vivo.</p>

      <p>El concierto será parte de su gira por América Latina, en la que visitará varias ciudades para celebrar con sus seguidores y demostrar por qué sigue siendo uno de los artistas más influyentes del rock en español.</p>

      <p>Los fanáticos en Colombia ya están a la expectativa de este gran evento, y las entradas seguramente se agotarán rápidamente. ¡Prepárate para vivir una noche de rock inolvidable con Enrique Bunbury en 2025!</p>
    `,
  }
];

export default newsInEnglish;