const newsInEnglish = [
  {
    id: 'news-3-fest-2025',
    title: '2025: A Year of Celebration',
    image: 'assets/images/news/2025-colombia-celebrate.jpg',
    date: '15/11/2024',
    description: `
      <p>Colombia is gearing up for an exciting year of festivals in 2025, with standout events across various musical and cultural genres. The Estéreo Picnic Festival, one of the most anticipated, will take place from March 27 to 30 in Bogotá, featuring a lineup of international and local stars. Artists like Olivia Rodrigo and the French duo Justice are among the confirmed acts, attracting both pop music fans and electronic scene enthusiasts.</p>
      <p>On the other hand, Medellín's Freedom Festival, dedicated to techno and house music, will celebrate its 17th edition from February 21 to 23. This event is known for its focus on emerging talent and groove, with activities such as workshops, academic panels, and live performances to complement its vibrant electronic music offerings.</p>
      <p>Finally, the Hay Festival, marking its 20th anniversary in Cartagena, will bring together writers, thinkers, and artists from around the world from January 30 to February 2. This year, the event will focus on 20 key questions about current topics, offering attendees an experience of dialogue and reflection.</p>
    `,
  },
  {
    id: 'news-2',
    title: 'Shakira World Tour',
    image: 'assets/images/news/shakira-world-tour.jpg',
    date: '09/10/2024',
    description: `<p>Global superstar Shakira is set to electrify South America in 2025 with her highly anticipated concert tour. Known for her dynamic performances, blend of Latin pop, rock, and reggaeton, and captivating stage presence, Shakira's tour is one of the most awaited events of the year.</p>

      <p>Fans can expect her to perform a mix of classic hits like "Hips Don’t Lie," "Whenever, Wherever," and new songs from her recent albums. The South American leg of the tour will likely cover major cities in Colombia, Argentina, Brazil, and Chile, giving her millions of regional fans the chance to experience her magic live.</p>

      <p>With Shakira’s reputation for high-energy shows and spectacular visuals, the 2025 tour promises an unforgettable experience, marking her grand return to the continent after years of global touring. The announcement has already sparked excitement, with tickets expected to sell out rapidly.</p>

      <p>Stay tuned for official dates, venues, and further details as Shakira gears up to conquer the South American stage once again!</p>`,
  },
  {
    id: 'news-1',
    title: 'Enrique Bunbury will return to Colombia',
    image: 'assets/images/news/bunbury-colombia-2025.jpeg',
    date: '07/10/2024',
    description: `<p>The legendary Spanish musician Enrique Bunbury will return to Colombia in 2025 to offer an unforgettable concert. Known for his distinctive voice and style that blends rock, bolero, and alternative sounds, Bunbury has garnered a solid fan base in Latin America.</p>
      <p>The former lead singer of Héroes del Silencio will perform both hits from his extensive solo career and classics that marked his journey, such as "Entre dos tierras" and "El extranjero." His show promises to be a unique experience, full of emotions and the characteristic energy of his live performances.</p>
      <p>The concert will be part of his Latin American tour, during which he will visit several cities to celebrate with his fans and demonstrate why he remains one of the most influential artists in Spanish rock.</p>
      <p>Fans in Colombia are already eagerly anticipating this major event, and tickets are sure to sell out quickly. Get ready for an unforgettable night of rock with Enrique Bunbury in 2025!</p>`,
  }
];

export default newsInEnglish;