export const testAttr = {
  FEST_INFO_CONTAINER: "fest-info-container",
  FEST_INFO_CONTENT: "fest-info-content",
  FEST_INFO_ALT_CONTENT: "fest-info-alt-content",
  FEST_INFO_ADS_NEWS: "fest-info-alt-ads-news",
};

export const imagesPath = "/assets/images/banner/";

export const mockData = {
  id: "01",
  isActive: true,
  name: "Carnaval de Negros y Blancos",
  city: "Pasto",
  cityDescription: `Pasto se le conoce como la ‘Ciudad Sorpresa’, y se destaca por la majestuosidad de su Carnaval de Negros y Blancos, y por sus atractivas construcciones, en especial los templos que sobresalen en cada esquina y que constituyen verdaderas joyas arquitectónicas. El municipio de Pasto está situado en el suroccidente de Colombia, en medio de la cordillera de los Andes en el macizo montañoso denominado nudo de los Pastos y la ciudad está situada en el denominado valle de Atriz, al pie del volcán Galeras y está muy cercana a la línea del ecuador y a una altitud de 2527 metros sobre el nivel del mar.`,
  state: "Nariño",
  festDate: 1,
  festDescription:
    "El Carnaval de Negros y Blancos es la fiesta más grande e importante del sur de Colombia, se celebra cada año en Pasto la primera semana de enero, atrayendo a un gran número de turistas provenientes de todos los rincones del país y del extranjero. El Carnaval de Negros y Blancos tiene su origen en la fusión de múltiples culturas y expresiones, correspondientes a los Andes, la Amazonia y la cultura del Pacífico. El carnaval de negros y blancos nació en el siglo XVI en el año 1546. Este hecho lo caracteriza y distingue entre otras expresiones similares, empezando por la fecha en que se realiza, la cual tiene un origen netamente indígena, puesto que coincide con la celebración de la Luna (Quilla), que guarda reminiscencia con los rituales efectuados por los pastos y los quilla singas, culturas agrarias que, en época de cosecha, honraban con danzas a la luna, y en otros rituales hacían rogativas al sol, para amparar sus cultivos.",
  activities: [
    "Ofrenda a la Virgen de Las Mercedes",
    "Carnavalito",
    "Desfile de Colectivos Coreográficos",
    "Día de los Negros",
    "Día de los Blancos",
    "Conciertos",
  ],
  imgPaths: "01-negros-blancos.jpg",
  bannerPath: "carnaval-negros-blancos.png",
  artistsList: [
    "La Tribu",
    "Trio Pambil",
    "Quinto Mandamiento",
    "Agrupsciones Musicales Románticas de la Región",
    "Las Añez",
    "Purik Dreams",
    "Descomunal",
    "Orquestas y Grupos Tropicales",
  ],
};
