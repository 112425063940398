import React from "react";
import PropTypes from "prop-types";
import Banner from "../../Atoms/Banner/Banner";
import NewsList from "../../Atoms/NewsList/NewsList";
import { testAttr, imagesPath } from "./Constants";
import { useTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import parse from 'html-react-parser';
import i18n from "../../../i18n";
import "./FestInfo.scss";

const FestInfo = ({ content, newsList }) => {
  const { t } = useTranslation();

  const LogoComp = () => <div className="imgLogo" />;

  return (
    <I18nextProvider i18n={i18n}>
      <Banner path={`${imagesPath}${content?.bannerPath}`} />
      <div data-testid={testAttr.FEST_INFO_CONTAINER} className="infoContainer">
        <div
          data-testid={testAttr.FEST_INFO_CONTENT}
          className="primaryContent"
        >
          <div className="customFestPageTitles">
            <h3>{content?.name}</h3>
          </div>
          <label>{t("aboutCity")}</label>
          <p>{parse(content?.cityDescription)}</p>
          <label>{t("aboutFest")}</label>
          <p>{parse(content?.festDescription)}</p>
          <label>{t("activities")}</label>
          <ul>
            {content?.activities.map((item, idx) => (
              <li key={`'list-'${idx}`}>
                <LogoComp />
                {item}
              </li>
            ))}
          </ul>
          <div className="artistList">
            <h3 className="ctaBlue">{t("artistsTitle").toUpperCase()}</h3>
            <p>
              {content?.artistsList.map(
                (item, idx) =>
                  `${item.toUpperCase()} ${
                    idx !== content?.artistsList.length - 1 ? " - " : ""
                  }`
              )}
            </p>
          </div>
        </div>
        <div
          data-testid={testAttr.FEST_INFO_ALT_CONTENT}
          className="secondaryContent"
        >
          <div data-testid={testAttr.FEST_INFO_ADS_NEWS}>
            <NewsList list={newsList.slice(0, 2)} onItemClick={() => {}}/>
            <div></div>
          </div>
        </div>
      </div>
    </I18nextProvider>
  );
};

FestInfo.propTypes = {
  content: PropTypes.object,
  newsList: PropTypes.array.isRequired,
};

export default FestInfo;
