export const testAttributes = {
  MODAL_COMPONENT: "modal_component",
  MODAL_TITLE: "modal_title",
  MODAL_DESC: "modal_description",
};

export const testData = {
  legalNote: "legalWarningTitle",
  legalWarning: "legalWarning",
};
