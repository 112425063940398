import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { testAttr } from "./Constants";

const CardContainer = ({ id, title, festDescription, path, size = "520", onClick }) => (
  <Card
    sx={{ maxWidth: size }}
    data-testid={`${testAttr.CARD_CONTAINER}-${id}`}
  >
    <CardActionArea onClick={onClick}>
      <CardMedia component="img" image={path} alt={title} />
      <CardContent>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Typography 
          variant="body2"
          color="text.secondary"
          sx={{
            overflow: 'hidden',
            display: '-webkit-box',
            'WebkitLineClamp': 5,
            'WebkitBoxOrient': 'vertical'
          }}
        >
          {festDescription}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

CardContainer.propTypes = {
  // Titlte of the fest
  title: PropTypes.string.isRequired,
  // Description, fest description
  festDescription: PropTypes.string.isRequired,
  // Path, especifies the path of the fest image
  path: PropTypes.string.isRequired,
  // Size, height of the image
  size: PropTypes.string,
  // Id, element identifies
  id: PropTypes.string,
  // Fn to trigger navigation
  onClick: PropTypes.func,
};

export default CardContainer;
