import React from "react";
import PropTypes from "prop-types";
import CardContainer from "../../Atoms/Card/Card";
import { imagesPath } from "../Home/Constants";
import { testAttr } from "./Constants";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import "./FestList.scss";

const FestsList = ({ festList, onClickFest }) => {
  const festivalsDataOrderByDate = festList.sort((festA, festB) =>
    festA.festDate > festB.festDate ? 1 : -1
  );

  const navigate = useNavigate();
  const handleCardClick = (festId, festName) => {
    const customUrl = festName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-");
    navigate(`/fest/${customUrl}`);
    onClickFest(festId);
  };

  return (
    <div
      className="listContainer"
      data-testid={testAttr.FEST_LIST_PAGE_CONTAINER}
    >
      {festivalsDataOrderByDate?.map((fest) => (
        fest.isActive && <CardContainer
          key={fest?.id}
          title={fest?.name}
          festDescription={parse(fest?.festDescription)}
          path={`${imagesPath}${fest?.imgPaths}`}
          onClick={() => handleCardClick(fest.id, fest.name)}
        />
      ))}
    </div>
  );
};

FestsList.propTypes = {
  festList: PropTypes.array.isRequired,
  onClickFest: PropTypes.func.isRequired,
};

export default FestsList;
