const dataInSpanish = [
  {
    id: "01",
    isActive: true,
    name: "Carnaval de Negros y Blancos",
    city: "Pasto",
    cityDescription: `Pasto se le conoce como la ‘Ciudad Sorpresa’, y se destaca por la majestuosidad de su Carnaval de Negros y Blancos, y por sus atractivas construcciones, en especial los templos que sobresalen en cada esquina y que constituyen verdaderas joyas arquitectónicas. El municipio de Pasto está situado en el suroccidente de Colombia, en medio de la cordillera de los Andes en el macizo montañoso denominado nudo de los Pastos y la ciudad está situada en el denominado valle de Atriz, al pie del volcán Galeras y está muy cercana a la línea del ecuador y a una altitud de 2527 metros sobre el nivel del mar.`,
    state: "Nariño",
    festDate: 1,
    festDescription:
      "El Carnaval de Negros y Blancos es la fiesta más grande e importante del sur de Colombia, se celebra cada año en Pasto la primera semana de enero, atrayendo a un gran número de turistas provenientes de todos los rincones del país y del extranjero. El Carnaval de Negros y Blancos tiene su origen en la fusión de múltiples culturas y expresiones, correspondientes a los Andes, la Amazonia y la cultura del Pacífico. El carnaval de negros y blancos nació en el siglo XVI en el año 1546. Este hecho lo caracteriza y distingue entre otras expresiones similares, empezando por la fecha en que se realiza, la cual tiene un origen netamente indígena, puesto que coincide con la celebración de la Luna (Quilla), que guarda reminiscencia con los rituales efectuados por los pastos y los quilla singas, culturas agrarias que, en época de cosecha, honraban con danzas a la luna, y en otros rituales hacían rogativas al sol, para amparar sus cultivos.",
    activities: [
      "Ofrenda a la Virgen de Las Mercedes",
      "Carnavalito",
      "Desfile de Colectivos Coreográficos",
      "Día de los Negros",
      "Día de los Blancos",
      "Conciertos",
    ],
    imgPaths: "01-negros-blancos.jpg",
    bannerPath: "carnaval-negros-blancos.jpg",
    artistsList: [
      "CONCIERTO DE MÚSICA ROMÁNTICA Y BALADAS EN ESPAÑOL",
      "ROCK CARNAVAL",
      "SALSA",
      "CONCIERTO DE MÚSICA CAMPESINA",
      "Orquestas y Grupos Tropicales",
    ],
  },
  {
    id: "02",
    isActive: true,
    name: "Feria de Manizales",
    city: "Manizales",
    cityDescription:
      "Manizales es un municipio colombiano, capital del departamento de Caldas. Está ubicado en el centro occidente de Colombia en el eje cafetero, sobre la Cordillera Central de los Andes, cerca del Nevado del Ruiz. La ciudad de las puertas abiertas, así se le conoce a este destino que siempre recibe a visitantes locales y de todo el mundo con la mejor energía. Recorrer sus destinos naturales, sus calles decoradas con hermosas casas de tradición y los lugares que dan vida al mejor café son las excusas perfectas para no perderse un viaje increíble en sitios turísticos de Manizales. Una ciudad que permanentemente te ofrece nieves perpetuas, bosques húmedos de transición, plantas florecidas todo el año y días soleados y cálidos, que te invitan a disfrutar de las piscinas al aire libre y los paseos por territorios diversos habitados por innumerables aves, bellos insectos y lo más importante la más cálida y amigable población humana.",
    state: "Caldas",
    festDate: 1,
    festDescription: `La Feria de Manizales es la primera y la feria más grande, importante y emblemática de la ciudad colombiana de Manizales y del continente americano. Alrededor de la Fiesta Taurina que atrae grandes figuras nacionales e internacionales con espectáculos propios de la auténtica Fiesta Brava, la Feria de Manizales reconocida por su temporada taurina, sus eventos, conciertos, exposiciones y por el Reinado Internacional del Café, desfiles, espectáculos musicales del folclor nacional entre muchas otras cosas que ofrece esta épica feria en su extensa programación llena de grandes eventos. Cabe resaltar que esta celebración anual es considerada la mejor, la única y la gran primera Feria de todo América.
      Esta feria tiene raíces españolas y está inspirada en la Feria de Abril de Sevilla en España, adoptando varias de sus costumbres, aunque también con muchas costumbres de la región cafetera; costumbres paisas, especialmente de la región andina.`,
    activities: [
      "Corridas de Toros",
      "Reinado Internacional del Café",
      "Exposiciones Artesanales",
      "Conciertos",
    ],
    imgPaths: "02-feria-de-manizales.jpg",
    bannerPath: "feria-manizales.jpg",
    artistsList: [
      "Yeison Jiménez",
      "El Andariego",
      "Maelo Ruiz",
      "Grupo Galé",
      "Jonny Colón",
      "Daniel Calderón y Los Gigantes",
      "Alex Manga",
      "Ñejo",
      "Juan Duque",
      "Jorge Celedón",
      "Orquesta Sinfónica de Caldas",
      "Orquestas y Grupos Tropicales",
    ],
  },
  {
    id: "03",
    isActive: true,
    name: "Carnaval de Barranquilla",
    city: "Barranquilla",
    cityDescription: `Barranquilla, es la capital del departamento del Atlántico, Colombia. También conocida como "La Arenosa" es una de las principales ciudades de Colombia, así como un destino turístico clave para locales y extranjeros. Esta ciudad, donde el río Magdalena termina su largo recorrido, es cuna de grandes músicos, literatos y pintores. Cuenta con uno de los puertos de mayor importancia para el país. Por ello, la ciudad también es conocida como la ‘Puerta de Oro’ de Colombia. Esto le ha permitido posicionarse como una ciudad con un alto potencial para el desarrollo económico e industrial. `,
    state: "Atlántico",
    festDate: 2,
    festDescription: `El carnaval de Barranquilla es un evento folclórico y cultural de Colombia. Cerca de 2 millones de personas, entre visitantes y locales, participan anualmente de la fiesta. El carnaval de Barranquilla es un acontecimiento en el que se expresan todas las variedades culturales y el folclor de la Costa Caribe Colombiana, así como las más variadas manifestaciones locales, como la música popular y el baile. Son motivo de risas y sustos los muchos disfraces que invocan todo tipo de especies animales, nativas y extrañas; negros africanos; cabezones; dementes; muñecotas; superhéroes; seres mitológicos, que trascienden los límites de la sexualidad y la Muerte, entre un número casi interminable de invenciones y ocurrencias populares. Algunos de los disfraces más tradicionales del carnaval de Barranquilla son la Marimonda, el Garabato, el Congo y el Monocuco.
      El Carnaval de Barranquilla es declarado por la UNESCO, Obra Maestra del Patrimonio Oral e Intangible de la Humanidad.
      `,
    activities: [
      "Desfiles con grupos folclóricos, orquestas, reinas, comparsas de Tradición y Fantasía",
      "Baila la Calle",
      "Conciertos",
    ],
    imgPaths: "03-carnaval-de-barranquilla.png",
    bannerPath: "carnaval-barranquilla.png",
    artistsList: [
      "Maluma",
      "Silvestre Dangond",
      "Ilegales",
      "Sergio Vargas",
      "Checo Acosta",
      "Rafa Perez",
      "Bobby Cruz",
      "Festival de Orquestas",
    ],
  },
  {
    id: "04",
    isActive: true,
    name: "Festival Nacional de la Música Colombiana",
    city: "Ibagué",
    cityDescription: `Ibagué es un municipio colombiano ubicado en el centro-occidente de Colombia, sobre la Cordillera Central de los Andes. Es la capital del departamento de Tolima.
      Es llamada La Capital Musical de Colombia, título que le otorgó el francés Conde de Gabriac en sus crónicas de viaje publicadas en Europa hacia 1886 quien quedó sorprendido con el ambiente musical en la ciudad, sus coloridos murales de los edificios del centro y el Conservatorio del Tolima, considerado como una de las escuelas de música más importantes en el país. Ibagué cuenta con una gran variedad de parques nacionales y ecológicos que puedes visitar para apreciar la flora y fauna de la región.`,
    state: "Tolima",
    festDate: 3,
    festDescription:
      "Desde su creación el 21 de Marzo de 1987 como Homenaje al Dueto Garzón y Collazos, se ha realizado anualmente sin interrupción. Este Festival Nacional fue institucionalizado con el propósito de perpetuar la herencia artística del inmortal Dueto “Garzón y Collazos” pues tras su huella y bajo el impulso que su dimensión forjara, se han conformado otros tantos Duetos en el Tolima y en el resto del país, los que día a día trabajan por la conservación del patrimonio musical de Colombia.",
    activities: [
      "Concurso Nacional de Duetos",
      "Príncipes de la Canción",
      "Concurso Nacional de Composición",
    ],
    imgPaths: "04-festival-musica-colombiana.jpg",
    bannerPath: "festival-nacional-musica-colombiana.png",
    artistsList: ["Artistas y compositores del folclor y la música Colombiana"],
  },
  {
    id: "05",
    isActive: true,
    name: "Paipa Color Festival",
    city: "Paipa",
    cityDescription: `Paipa (originalmente Villa de San Miguel Arcángel de Paipa) es un municipio colombiano situado en el centro-oriente de Colombia.
      Paipa es una población boyacense, turística por excelencia, famosa por sus aguas termales a las que les atribuyen beneficios terapéuticos, por la bondad de su clima y la belleza de sus paisajes. Además de estas características, Paipa es conocida por productos típicos como la almojábana, el pan de yuca y otros productos más.
      Es considerado el municipio boyacense con mejor infraestructura hotelera del departamento, además por su tranquilidad y ubicación es perfecto para buscar descanso y recreación, lo mismo que para la realización de congresos y seminarios.`,
    state: "Boyacá",
    festDate: 8,
    festDescription: `Desde el año 2016 se realiza el Paipa Color Festival, en el que todos los asistentes pueden participar de un ambiente lleno de diversión, música electrónica y mucho color. Paipa Color Festival, un evento lleno de color que se celebra todos los años, generando espacios de integración, igualdad, esparcimiento y convivencia.
      El festival, inspirado en el Holi de la India, siempre cuenta con la participación de destacados exponentes de la música electrónica en Colombia, quienes hacen gala del mejor sonido y de un juego de luces, mientras el público lanza, en medio de la euforia y sin cesar, polvo de colores.  Hay diferentes ambientes para el esparcimiento del público: zona de integración, zona del amor, zona de deportes y el mercado artesanal.`,
    activities: [
      "Conciertos Música Electrónica, Rap, Rock",
      "Deportes extremos",
      "Mercado Artesanal",
    ],
    imgPaths: "05-paipa-color-festival.jpg",
    bannerPath: "paipa-color-festival.png",
    artistsList: ["Música Electrónica", "DJs Locales"],
  },
  {
    id: "06",
    isActive: true,
    name: "Festival de la Leyenda Vallenata",
    city: "Valledupar",
    cityDescription: `Valledupar, también llamada Ciudad de los Santos Reyes del Valle de Upar, es un municipio colombiano, capital del departamento del Cesar.La ciudad es un importante centro para la producción agrícola, agroindustrial y ganadera en la región comprendida entre el norte del departamento del Cesar y el sur del departamento de La Guajira, en el punto intermedio de las dos cuencas de explotación carbonífera más grandes del país.
      También es uno de los principales epicentros musicales, culturales y folclóricos de Colombia por ser la cuna del vallenato, género musical de mayor popularidad en el país y actualmente símbolo de la música colombiana. Anualmente atrae a miles de visitantes de Colombia y del exterior durante el Festival de la Leyenda Vallenata, máximo evento del vallenato.`,
    state: "Cesar",
    festDate: 4,
    festDescription: `En 1968, tres personas pensaron que era hora de hacer algo para que todo ese acervo cultural y musical no desapareciera en las nebulosas del tiempo, y decidieron crear el Festival de La Leyenda Vallenata para recrear toda la magia de una tierra donde los mitos, las costumbres, las propias vivencias y una riqueza lingüística y oral nutren día por día la literatura y el pentagrama donde se tejen las letras y las melodías del vallenato.
      El Festival de la Leyenda Vallenata de 1999, marcó sin lugar a duda un hito en su historia ya que la transmisión de Televisión se realizó por primera vez a nivel nacional e internacional. También se efectuó la coronación de cinco “Reyes Vitalicios” que fueron seleccionados por representar escuelas del Vallenato en la Costa Atlántica, y por su trayectoria como reconocidos acordeoneros de la región que los cataloga como verdaderos juglares. De esta forma estuvieron representadas las tres escuelas: el Vallenato-Bajero de la región del Magdalena y Bolívar con Francisco “Pacho” Rada y Abel Antonio Villa; el Vallenato-Sabanero de Sucre y Córdoba, en Andrés Landero; y el Vallenato-Vallenato del Cesar y la Guajira en Antonio Salas y Lorenzo Morales. Estos hechos han aumentado sin lugar a dudas la trascendencia y proyección en este evento que ha colmado plenamente las expectativas del público amante de este folclor.`,
    activities: [
      "Concurso de Acordeoneros, Categorias Infantil, Juvenil Aficionado, Mayor",
      "Concurso de Piqueria",
      "Espectáculo Musical, Cultural y Folclórico - Concierto",
    ],
    imgPaths: "06-festival-Leyenda-Vallenata.jpg",
    bannerPath: "festival-leyenda-vallenata.png",
    artistsList: [
      "Juan Luis Guerra",
      "Grupo Frontera",
      "Iván Villazón",
      "Peter Manjarrés",
      "Elder Dayán"
    ],
  },
  {
    id: "07",
    isActive: true,
    name: "Fiestas del Mar",
    city: "Santa Marta",
    cityDescription: `Santa Marta, es la capital del departamento de Magdalena, Colombia.
      Es conocida por sus actividades turísticas, la historia de sus calles y sus playas. El balneario El Rodadero es uno de los principales destinos del Caribe colombiano. Su casco urbano se encuentra entre la Sierra Nevada de Santa Marta y el mar Caribe y el parque Tayrona se encuentra bajo su jurisdicción. Entre sus atracciones culturales e históricas están la Casa de la Aduana, la Catedral Basílica, la Biblioteca Banco de la República, el Seminario San Juan Nepomuceno, el Malecón de la Bahía, la Quinta de San Pedro Alejandrino, la plaza de Bolívar y el parque Los Novios.`,
    state: "Magdalena",
    festDate: 7,
    festDescription: `Todos los años, a finales de julio, Santa Marta se viste de gala para celebrar la Fiesta del Mar. Se trata de una gran vitrina turística cuya actividad principal son los deportes acuáticos, acompañados de diferentes actividades culturales, musicales, bailes y diversión.`,
    activities: [
      "Juegos Nacionales Náuticos y de Playas",
      "Reinado Nacional e Internacional del Mar",
      "Actividades Culturales",
      "Bailes, Música y Diversión",
    ],
    imgPaths: "07-fiestas-mar.jpg",
    bannerPath: "fiestas-mar.png",
    artistsList: ["Silvestre Dangond", "Beto Zabaleta", "Poncho Zuleta", "Peter Manjarrés", "Iván Villazón", "Elder Dayan", "Koffe El Kafetero", "GiBlack", "Michell Torres", "La Big Band Samaria"],
  },
  {
    id: "08",
    isActive: false,
    name: "Festival Cartagena Pride",
    city: "Cartagena",
    cityDescription: `Cartagena de Indias, es la capital del departamento de Bolívar, al norte de Colombia.
      A partir de su fundación en el siglo xvi y durante toda la época virreinal española, Cartagena de Indias fue uno de los puertos más importantes de la América española. De esta época procede la mayor parte de su patrimonio artístico y cultural. El 11 de noviembre de 1811, Cartagena se declaró independiente de España. Este día es fiesta nacional en Colombia y en la ciudad se celebra durante cuatro días conocidos como las "Fiestas de Independencia".
      Cartagena ha sido una ciudad presente en medio de conflictos bélicos como también en la piratería, pues fue aquí donde se produjeron numerosos ataques por parte de los piratas y corsarios provenientes de Europa, lo cual supuso que fuera fuertemente amurallada y fortificada durante la administración española, hasta el punto de ser la fortaleza más robusta de América del Sur y del Caribe, llegando a estar casi tan reforzada como el mismo Golfo de México en su época. En la actualidad se mantiene su arquitectura virreinal.`,
    state: "Bolivar",
    festDate: 8,
    festDescription: `Cartagena Pride es la celebración real del Orgullo LGBTI. Anualmente durante toda una semana el hermoso Corralito de Piedra, como también se le conoce a Cartagena, se llena de eventos especiales, actividades artísticas, culturales, deportivas, empresariales, turísticas, gastronómicas y de entretenimiento. Nuestro principal orgullo es que en una misma ciudad reunimos a lesbianas, gays, bisexuales, transgéneros, sus amigos, aliados y simpatizantes en la celebración del espíritu y la cultura únicos de la comunidad.
      ¿Cómo surgió la idea?
      Inicialmente nació como una marca de entretenimiento LGBT de Barranquilla en el 2013, de donde surgió la idea de crear el Pride Festival de nivel internacional en Cartagena, como las que se realizan en otras ciudades del mundo, como San Francisco y Nueva York.

      Es así como el Pride Festival, que busca el reconocimiento de la comunidad LGBT construyendo escenarios de armonía y bienestar, durante una semana se llena de actividades artísticas, culturales, deportivas, empresariales, turísticas, gastronómicas y de entretenimiento.
      En tu visita a Medellín, descubrirás por qué la capital de Antioquia es un ejemplo de innovación: modernos edificios y soluciones avanzadas en todos los ámbitos de la vida se pueden apreciar en cualquier rincón de la ciudad, de hecho en el año 2013 Medellín fue nombrada la Ciudad más Innovadora del Mundo en el concurso City of the Year del Wall Street Journal.`,
    activities: [
      "Actividades Artísticas",
      "Actividades Culturales",
      "Actividades Empresariales",
      "Actividades de Entretenimiento, Música, Baile",
    ],
    imgPaths: "08-festival-cartagena-pride.jpg",
    bannerPath: "festival-cartagena-pride.png",
    artistsList: ["Música Electrónica", "DJs Locales"],
  },
  {
    id: "09",
    isActive: true,
    name: "Feria de las Flores",
    city: "Medellin",
    cityDescription: `Medellín, mejor conocida como la ciudad de la eterna primavera es la capital del departamento de Antioquia. Ubicada en un valle (Valle de) rodeado de montañas con un clima agradable.
      Medellín es una ciudad cautivadora, no solo por lo hermoso de sus paisajes, también por la amabilidad de su gente, su deliciosa comida y ese encanto paisa que enamora a los visitantes.`,
    state: "Antioquia",
    festDate: 8,
    festDescription: `Feria de las Flores, una festividad que no querrás perderte si quieres conocer la mezcla entre tradición y modernidad que ha hecho de Medellín un ejemplo de crecimiento e innovación en todo el mundo.
      Esta celebración, que nació en 1957, rinde un homenaje de diez días a las bellas flores que se cultivan en la región. Este festival es la celebración más emblemática de la ciudad, y se constituye en un ícono raizal, cultural e histórico, tal como son por ejemplo el Carnaval de Río en Río de Janeiro, la Fiesta de Octubre (Oktoberfest) en Baviera, Alemania, o también en Colombia la Feria de Manizales, el Carnaval de Barranquilla, la Feria de Cali o el Carnaval de Negros y Blancos en la ciudad de San Juan de Pasto.
      El ambiente de carnaval que vive Medellín durante estos días ofrece una amplia diversidad de eventos y espectáculos, no todos relacionados con las flores, pero todo el jolgorio lleva ese nombre general de "Feria de las flores" puesto que las flores son el marco principal de las festividades, a la vez que ofrecen los espectáculos y escenas más llamativas y bellas, además únicas en el mundo.`,
    activities: [
      "Desfile de Silleteros",
      "Desfile de Autos clásicos y Antiguos",
      "Festival Nacional de la Trova",
      "Muestras Gastronómicas",
      "Feria Nacional Equina",
      "Campeonato Nacional de Sonido sobre Ruedas",
      "Actividades Culturales Propias de la Región",
      "Conciertos",
    ],
    imgPaths: "09-feria-flores.jpg",
    bannerPath: "feria-flores.png",
    artistsList: [
      "Jorge Celedón", 
      "Pipe Peláez",
      "Arelys Henao",
      "Jean Carlos Centeno",
      "Daniel Calderón",
      "Mauro Castillo ",
      "Hebert Vargas",
      "Pipe Bueno",
      "Binomio de Oro",
      "El Combo de las Estrellas",
      "Dálmata",
    ],
  },
  {
    id: "10",
    isActive: true,
    name: "Feria de Cali",
    city: "Santiago de Cali",
    cityDescription: `Santiago de Cali, capital del departamento de Valle del Cauca, es la tercera ciudad más poblada y el tercer centro económico y cultural de Colombia. Está situada en la región Sur del Valle del Cauca. Cali, es una ciudad que tiene atractivos turísticos con historia, una vida cultural muy activa y unos ritmos musicales que le han dado fama en todo el mundo.
      Por eso mismo, Cali se distingue en Colombia como la ‘Capital de la Rumba’ y, en el mundo, como la ‘Capital de la Salsa’, porque la fiesta callejera y el baile son característicos.`,
    state: "Valle del Cauca",
    festDate: 12,
    festDescription: `Este evento, que se celebra en la capital del Valle del Cauca, es un homenaje al sabor y el ritmo, especialmente a la salsa, género que ha dado fama universal a esta ciudad. De hecho, Cali es conocida como la ‘Capital Mundial de la Salsa’. 
      La Feria de Cali, que se lleva a cabo en la última semana de diciembre con la intención de despedir el año por todo lo alto, suele ofrecer más de 50 eventos para que los caleños y los turistas de todas las edades y gustos disfruten al máximo de esta gran celebración.`,
    activities: [
      "Cabalgata",
      "Salsódromo",
      "Carnaval del Cali Viejo",
      "Desfile de Autos Clásicos y Antiguos",
      "Feria taurina",
      "Encuentro de Salsómanos, Melómanos y Coleccionistas",
      "Conciertos",
    ],
    imgPaths: "10-feria-de-cali.jpg",
    bannerPath: "feria-de-cali.png",
    artistsList: [
      "Willie Colón",
      "Grupo Niche",
      "Oscar D'León",
      "Víctor Manuelle",
      "Rey Ruiz",
      "Gilberto Santa Rosa",
      "Guayacán Orquesta",
      "Mayito Rivera",
      "Willy García",
      "Javier Vásquez",
      "Jimmy Saa",
      "Ariacne Trujillo (Cuba)",
      "Mayito Rivera (Cuba) - Orquesta Kimbara",
      "Froyber Maya (Cali)",
      "Runa Pakary",
    ],
  },
  {
    id: "11",
    isActive: true,
    name: "Rock al Parque",
    city: "Bogotá",
    cityDescription: `Bogotá es la capital de la República de Colombia y del departamento de Cundinamarca.
      Es la tercera capital más alta del mundo (después de Quito y La Paz), ya que se encuentra a un promedio de 2.625 metros sobre el nivel del mar. Está ubicada en el centro de Colombia, en la región natural conocida como la sabana de Bogotá, que forma parte del altiplano cundiboyacense, formación ubicada en la cordillera Oriental de los Andes.
      Bogotá, es punto de convergencia de personas de todo el país, así que es diversa y multicultural, y en ella se combinan lo antiguo y lo moderno. Gracias a esta fusión entre pasado y presente, en la capital encontrarás un destino ideal con historia, diversión, gastronomía, cultura, negocios y mucho más.`,
    state: "Cundinamarca",
    festDate: 11,
    festDescription: `Rock al Parque es un festival internacional de rock que se lleva a cabo en la ciudad de Bogotá, Colombia, desde el año 1994. Es el festival gratuito y al aire libre más grande de Hispanoamérica e Iberoamérica y el tercero más grande del mundo. Ha sido escenario tanto para artistas de talla mundial como para los que recién empiezan a lanzar su carrera. Además, es uno de los Festivales al Parque, vinculados a diferentes géneros musicales como el jazz, el hip hop y la salsa.
      Algunos de los artistas y agrupaciones que han pisado la tarima del festival son: Fito Páez (Argentina), Christina Rosenvinge (España), Molotov (México), Los Auténticos Decadentes (Argentina), Café Tacuba (México), Aterciopelados (Colombia), Zona Ganjah (Argentina), Manu Chao (Francia), La Fuga (España) y Los Amigos Invisibles (Venezuela), Juanes (Colombia).`,
    activities: [
      "Conciertos",
      "Actividades Culturales",
      "Charlas Especiales de Rock al Parque",
    ],
    imgPaths: "11-rock-al-parque.jpg",
    bannerPath: "rock-al-parque.jpg",
    artistsList: [
      "In Flames",
      "Overkill",
      "Los Calzones",
      "Sonido Gallo",
      "Cabra",
      "Chemicide",
      "Javiera Mena",
      "Marilina Bertoldi",
      "Nonpalidece",
      "Los Punsetes",
      "Noprocede",
      "Jenny Woo",
      "Ensamble Arsis", 
      "Latenaz",
      "No Dependiente",
      "Munnopsis",
      "Alto Grado"
    ],
  },
  {
    id: "12",
    isActive: true,
    name: "Festival Altavoz",
    city: "Medellín",
    cityDescription: `Medellín, mejor conocida como la ciudad de la eterna primavera es la capital del departamento de Antioquia. Ubicada en un valle (Valle de) rodeado de montañas con un clima agradable.
      Medellín es una ciudad cautivadora, no solo por lo hermoso de sus paisajes, también por la amabilidad de su gente, su deliciosa comida y ese encanto paisa que enamora a los visitantes.`,
    state: "Antioquia",
    festDate: 10,
    festDescription: `Festival Internacional Altavoz es un festival de rock que se lleva a cabo en la ciudad de Medellín (Colombia), desde el año 2004.
      El festival nació de la idea de crear un espacio para los jóvenes y los músicos de la ciudad expusieran su música.`,
    activities: ["Conciertos", "Actividades Culturales"],
    imgPaths: "12-altavoz.jpg",
    bannerPath: "altavoz.png",
    artistsList: [
      "P.O.D.",
      "GZA",
      "Cristina y los Subterráneos",
      "Los Rabanes",
      "Suicidal Angels",
      "Tenside",
      "Kei Linch",
      "Margarita Siempre Viva",
      "Masacre",
      "Fértil Miseria",
      "Folkombia",
      "Illary",
      "Addiction",
      "Danger"
    ],
  },
  {
    id: "13",
    isActive: true,
    name: "Festival Estéreo Picnic",
    city: "Bogotá",
    cityDescription: `Bogotá es la capital de la República de Colombia y del departamento de Cundinamarca.
      Es la tercera capital más alta del mundo (después de Quito y La Paz), ya que se encuentra a un promedio de 2.625 metros sobre el nivel del mar. Está ubicada en el centro de Colombia, en la región natural conocida como la sabana de Bogotá, que forma parte del altiplano cundiboyacense, formación ubicada en la cordillera Oriental de los Andes.
      Bogotá, es punto de convergencia de personas de todo el país, así que es diversa y multicultural, y en ella se combinan lo antiguo y lo moderno. Gracias a esta fusión entre pasado y presente, en la capital encontrarás un destino ideal con historia, diversión, gastronomía, cultura, negocios y mucho más.`,
    state: "Cundinamarca",
    festDate: 3,
    festDescription: `Festival Estéreo Picnic es un festival de música que se ha venido realizando en la Sabana de Bogotá con artistas nacionales e internacionales que ofrecen música de géneros Rock, Electrónica, Pop y Nuevo Folcklore entre muchos otros. El evento consta también de actividades culturales y de entretenimiento, alternas a los conciertos en donde los asistentes pueden disfrutar de comida y arte al aire libre. Con la edición de 2018 ya son nueve, a través de las cuales sus organizadores han querido enfocar la experiencia musical con artistas de amplio reconocimiento internacional, nacional y local, acompañados con actividades paralelas tales como la muestra comercial denominada "Hippie Market", y mucho espacio para la convivencia entre los asistentes al estilo de grandes festivales del mundo como Coachella y Lollapalooza.`,
    activities: [
      "Conciertos",
      "Actividades Culturales y de Entretenimiento",
      "Hippie Market",
    ],
    imgPaths: "13-estereo-picnic.jpg",
    bannerPath: "estereo-picnic.png",
    artistsList: [
      "Justin Timberlake",
      "Incubus",
      "Benson Boone",
      "Shawn Mendes",
      "Foster the People",
      "Tools",
      "Alanis Morissette",
      "The Black Keys",
      "Justice",
      "Zedd",
      "Olivia Rodrigo",
      "Mon Laferte",
      "Rüfüs Du Sol",
      "LosPetitFellas",
      "Tate McRae",
      "Parcels",
      "The Hives",
      "Empire of the Sun"
    ],
  },
  {
    id: "14",
    isActive: true,
    name: "Festival Cordillera",
    city: "Bogotá",
    cityDescription: `Bogotá es la capital de la República de Colombia y del departamento de Cundinamarca.
      Es la tercera capital más alta del mundo (después de Quito y La Paz), ya que se encuentra a un promedio de 2.625 metros sobre el nivel del mar. Está ubicada en el centro de Colombia, en la región natural conocida como la sabana de Bogotá, que forma parte del altiplano cundiboyacense, formación ubicada en la cordillera Oriental de los Andes.
      Bogotá, es punto de convergencia de personas de todo el país, así que es diversa y multicultural, y en ella se combinan lo antiguo y lo moderno. Gracias a esta fusión entre pasado y presente, en la capital encontrarás un destino ideal con historia, diversión, gastronomía, cultura, negocios y mucho más.`,
    state: "Cundinamarca",
    festDate: 9,
    festDescription: `El Festival Cordillera es un evento musical que se celebra en Bogotá, Colombia, desde 2022. Su enfoque principal es destacar el talento latinoamericano, reuniendo a artistas de diversos géneros musicales con un fuerte componente de música rock, indie, pop, y géneros alternativos. El nombre "Cordillera" se inspira en la geografía de la región andina, que simboliza la conexión y unión entre los países latinoamericanos a través de la música y la cultura.
      El festival se realiza en el Parque Simón Bolívar, uno de los escenarios más grandes de Bogotá para eventos al aire libre, y ha ganado reconocimiento por traer tanto a grandes nombres de la música latinoamericana como a artistas emergentes. Además de su enfoque musical, el Festival Cordillera busca ser un evento sostenible, con iniciativas para reducir el impacto ambiental.`,
    activities: [
      "Conciertos",
      "Actividades Culturales y de Entretenimiento",
    ],
    imgPaths: "14-fest-cordillera.png",
    bannerPath: "festival-cordillera.jpeg",
    artistsList: [
      "Juan Luis Guerra 4.40",
      "Hombres G",
      "Miranda!",
      "Vilma Palma e Vampiros",
      "Los Fabulosos Cadillacs",
      "Fonseca",
      "Molotov",
      "Rels B",
      "Babasonicos",
      "Maldita Vecindad",
      "Nicolle Jadad",
      "Bersuit Vergarabat",
      "Morodo & Okoumé Lions"
    ],
  },
  {
    id: "15",
    isActive: true,
    name: "Hip Hop al Parque",
    city: "Bogotá",
    cityDescription: `Bogotá es la capital de la República de Colombia y del departamento de Cundinamarca.
      Es la tercera capital más alta del mundo (después de Quito y La Paz), ya que se encuentra a un promedio de 2.625 metros sobre el nivel del mar. Está ubicada en el centro de Colombia, en la región natural conocida como la sabana de Bogotá, que forma parte del altiplano cundiboyacense, formación ubicada en la cordillera Oriental de los Andes.
      Bogotá, es punto de convergencia de personas de todo el país, así que es diversa y multicultural, y en ella se combinan lo antiguo y lo moderno. Gracias a esta fusión entre pasado y presente, en la capital encontrarás un destino ideal con historia, diversión, gastronomía, cultura, negocios y mucho más.`,
    state: "Cundinamarca",
    festDate: 8,
    festDescription: `
      <p>Bogotá, la vibrante capital de Colombia, es el escenario de Hip Hop al Parque, uno de los festivales más importantes de música urbana en América Latina. Desde su creación en 1996, este evento ha reunido a los amantes de la cultura hip-hop para celebrar su música, arte y valores, consolidándose como un espacio de expresión y encuentro para las nuevas generaciones.</p>

      <p><strong>Un espacio para la cultura y la comunidad</strong>
      Hip Hop al Parque no es solo un festival musical, sino una plataforma para toda la cultura hip-hop. Además de los conciertos, se destacan actividades relacionadas con los elementos fundamentales del movimiento: el rap, el breakdance, el graffiti y el DJing. Los asistentes disfrutan de batallas de freestyle, talleres de arte urbano y exhibiciones de baile, lo que convierte al festival en un lugar de aprendizaje, intercambio y creatividad.</p>

      <p><strong>Gratuito y para todos</strong>
      Una de las características más especiales de Hip Hop al Parque es su entrada gratuita, gracias al apoyo del Instituto Distrital de las Artes (Idartes) y la Alcaldía de Bogotá. Esto permite que miles de personas, sin importar su condición económica, puedan disfrutar de este evento masivo en espacios emblemáticos como el Parque Simón Bolívar.</p>
    `,
    activities: [
      "Conciertos",
      "Batallas de freestyle",
      "Breakdance & exhibiciones de baile",
      "Graffiti y el DJing",
      "Talleres de arte urbano",
      "Actividades Culturales y de Entretenimiento",
    ],
    imgPaths: "15-hip-hop-al-parque.jpg",
    bannerPath: "hip-hop-al-parque.jpg",
    artistsList: [
      "Cormega",
      "Hanna Hasen",
      "Elorik",
      "Ciudadano Z",
      "Hard Crew",
      "Tatiana Gomez",
      "Mcktrices",
      "Frank Takuma",
      "J Noa",
      "DJ Tony Touch",
      "Lia Kali",
      "Flaco Flow y Melanina",
      "La gran hambruna y Car3sucio",
      "KcK"
    ],
  },
];

export default dataInSpanish;
