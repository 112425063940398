import React from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../i18n";
import parse from "html-react-parser";
import { testAttributes } from "./Constants";
import "./ModalDialog.scss";

const ModalDialog = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const handleClose = () => setIsOpen(false);

  const customStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "calc(100% - 100px)",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fafafa",
    border: "2px solid #39b5e0",
    p: 4,
  };

  return (
    <I18nextProvider i18n={i18n}>
      <div data-testid={testAttributes.MODAL_COMPONENT}>
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={customStyle}>
            <Typography
              data-testid={testAttributes.MODAL_TITLE}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {t("legalWarningTitle")}
            </Typography>
            <Typography
              data-testid={testAttributes.MODAL_DESC}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              {parse(t("legalWarning"))}
            </Typography>
          </Box>
        </Modal>
      </div>
    </I18nextProvider>
  );
};

ModalDialog.propTypes = {
  // IsOpen, determines if modal is open or not, possibles values true or false
  isOpen: PropTypes.bool,
  // SetIsOpen, change isOpen prop
  setIsOpen: PropTypes.func,
};

export default ModalDialog;
