import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      // Header
      home: "HOME",
      festivals: "FESTIVALS",
      planYourTrip: "PLAN YOUR TRIP",
      // Titles - Labels
      aboutCity: "About the City",
      aboutFest: "About the Festival",
      activities: "Activities",
      readMore: "Read more",
      upcomingFestivals: "Upcoming festivals",
      // footer
      cookiesPolicy: "Cookies policy",
      legalWarningTitle: "Legal warning",
      legalWarning: `<b>El uso de esta página ratifica su acuerdo con los términos y condiciones expuestos a continuación.</b>
                     <br /><br /><b>COLOMBIA FEST</b> es un sitio web de carácter informativo y acceso gratuito, no se exige la previa suscripción o registro a nuestros usuarios.
                     <br /> El uso responsable de la información y las imágenes mostradas en este sitio web, queda sujeto al manejo responsable por parte de los usuarios.
                     <br /> En consecuencia, <b>COLOMBIA FEST</b> no se hace responsable y rechaza cualquier reclamo por cualquier pérdida, daño o perjuicio económico, de cualquier naturaleza,
                      ya sea directo o por consecuencia, que pudiera sufrir o que pudiera ser atribuible, directa o indirectamente, 
                      como resultado del uso o confianza respecto a cualquier información, enlaces o servicios brindados por terceros a través de este sitio web.
                      <br /> Todos los derechos, incluidos los de Propiedad Intelectual, respecto de El sitio web de este sitio pertenecen a <b>COLOMBIA FEST</b>. Al acceder a él, el usuario tiene el derecho de revisar toda la información que requiera en forma totalmente gratuita.`,
      copyRight: "COLOMBIA FEST All rights reserved",
      emailUs: "Contact us:",
      // Modal
      closeModal: "Close",
      // Other Labels
      artistsTitle: "guest artists",
      newsList: "Last News",
      newsTitle: "News"
    },
  },
  es: {
    translation: {
      // Header
      home: "INICIO",
      festivals: "FESTIVALES",
      planYourTrip: "PLANIFICA TU VIAJE",
      // Titles - Labels
      aboutCity: "Sobre la Ciudad",
      aboutFest: "Acerca del Festival",
      activities: "Actividades",
      readMore: "Ver más",
      upcomingFestivals: "Próximos festivales",
      // footer
      cookiesPolicy: "Política de cookies",
      legalWarningTitle: "Aviso legal",
      legalWarning: `<b>El uso de esta página ratifica su acuerdo con los términos y condiciones expuestos a continuación.</b>
                     <br /><br /><b>COLOMBIA FEST</b> es un sitio web de carácter informativo y acceso gratuito, no se exige la previa suscripción o registro a nuestros usuarios.
                     <br /> El uso responsable de la información y las imágenes mostradas en este sitio web, queda sujeto al manejo responsable por parte de los usuarios.
                     <br /> En consecuencia, <b>COLOMBIA FEST</b> no se hace responsable y rechaza cualquier reclamo por cualquier pérdida, daño o perjuicio económico, de cualquier naturaleza,
                      ya sea directo o por consecuencia, que pudiera sufrir o que pudiera ser atribuible, directa o indirectamente, 
                      como resultado del uso o confianza respecto a cualquier información, enlaces o servicios brindados por terceros a través de este sitio web.
                      <br /> Todos los derechos, incluidos los de Propiedad Intelectual, respecto de El sitio web de este sitio pertenecen a <b>COLOMBIA FEST</b>. Al acceder a él, el usuario tiene el derecho de revisar toda la información que requiera en forma totalmente gratuita.`,
      copyRight: "COLOMBIA FEST todos los derechos reservados",
      emailUs: "Contacta con nosotros:",
      // Modal
      closeModal: "Cerrar",
      // Other labels
      artistsTitle: "artistas invitados",
      newsList: "Últimas noticias",
      newsTitle: "Noticias"
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: false },
  });

export default i18n;
