import React from "react";
import PropTypes from "prop-types";

const LangContext = React.createContext();
const navigatorLang = navigator.language;

export const useSetLanguage = (lang) => {
  const languageContext = React.useContext(LangContext);
  return languageContext === "en-US" ? languageContext : lang;
};

export const LangContextProvider = ({ children }) => {
  return (
    <LangContext.Provider value={navigatorLang}>
      {children}
    </LangContext.Provider>
  );
};

LangContextProvider.propTypes = {
  // children prop
  children: PropTypes.any,
};
